import { Injectable } from '@angular/core';
import { Fetcher, TokenAmount, Percent, ChainId, Trade, Router } from '@traderjoe-xyz/sdk';
import { PairV2, RouteV2 } from '@traderjoe-xyz/sdk-v2';
import { utils, providers, Contract, BigNumber } from 'ethers';
import { inspect } from 'util';
import { ERC20_ABI, LIQUIDITY_PROVIDER_FEE_PERCENTAGE, MAX_INT_ALLOWANCE, TOKEN_ALLOWANCE, TRADER_JOE_V2_ABI, TRADER_JOE_V2_ROUTER_ADDRESS, TRADER_JOE_V2_QUOTER_ADDRESS, TRADER_JOE_V2_QUOTER_ABI } from '../constants';
import { Logger } from './logger.service';
import { NetworkService } from './network.service';
import { StorageService } from './storage.service';
import { TokenManagerService } from './token-manager.service';

type BestTrade = {
    route: Array<string>,
    pairs: Array<string>,
    binSteps: Array<number>,
    versions: Array<number>, 
    amounts: Array<string>, // Bigint
    virtualAmountsWithoutSlippage: Array<string>, // Bigint
    fees: Array<string> // Bigint
}

@Injectable({
providedIn: 'root'
})
export class TraderJoeV2Service {

    private network: any;
    private chainId: ChainId;
    private provider: providers.BaseProvider;
    private tokenBases = [];
    private swapV2ContractAddress: string;
    private quoteV2ContractAddress: string;
  
    private usdcAvalanche: string;
  
    constructor(
      private storage: StorageService,
      private networkService: NetworkService,
      private tokenManager: TokenManagerService
    ) { }

  // get all requried for once
  async initiateConnection() {
    this.network = await this.networkService.getActiveAvaxNetwork();
    this.chainId = this.network.chainId;
    this.provider = providers.getDefaultProvider(this.network.RPC_URL);
    
    this.swapV2ContractAddress = TRADER_JOE_V2_ROUTER_ADDRESS;
    this.quoteV2ContractAddress = TRADER_JOE_V2_QUOTER_ADDRESS;
    
    this.usdcAvalanche = "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E";
  }

  async cacheTokenAllowanceByAddress(
    owner: string,
    token: string,
    spender: string,
    allowanceValue: string
  ) {
    const spenderAllowance = { [spender]: allowanceValue };
    const tokenAllowance = { [token]: spenderAllowance };
    let userAllowances = await this.storage.get(TOKEN_ALLOWANCE);

    userAllowances = userAllowances ? JSON.parse(userAllowances) : {};
    if (userAllowances) {
      if (userAllowances[owner]) {
        if (userAllowances[owner][token]) {
          userAllowances[owner][token][spender] = allowanceValue;
        } else {
          userAllowances[owner][token] = spenderAllowance;
        }
      } else {
        tokenAllowance[token] = spenderAllowance;
        userAllowances[owner] = tokenAllowance;
      }
    } else {
      tokenAllowance[token] = spenderAllowance;
      userAllowances[owner] = tokenAllowance;
    }
    await this.storage.set(TOKEN_ALLOWANCE, JSON.stringify(userAllowances));
  }

  // get token allowance stored in local
  async getTokenAllowanceByAddress(
    owner: string,
    token: string,
    spender: string
  ) {
    let userAllowances = await this.storage.get(TOKEN_ALLOWANCE);
    if (userAllowances) {
      userAllowances = JSON.parse(userAllowances);
      try {
        return userAllowances[owner][token][spender];
      } catch (error) { }
    }
    return false;
  } 

  async checkAllowance(tokenAddress: string, walletAddress: string) {
    try {
      // get from cached allowances
      const cachedAllowance = await this.getTokenAllowanceByAddress(walletAddress, tokenAddress, this.swapV2ContractAddress);
      if (cachedAllowance) {
        return cachedAllowance as string;
      }

      const makerContract = new Contract(tokenAddress, ERC20_ABI, this.provider);
      const getAllowance = await makerContract.allowance(walletAddress, this.swapV2ContractAddress);
      const currentAllowance = utils.formatUnits(getAllowance);
      if (Number(currentAllowance) > 0) {
        try {
          await this.cacheTokenAllowanceByAddress(walletAddress, tokenAddress, this.swapV2ContractAddress, currentAllowance);
        } catch (error) { 
          Logger.error(`checkV2Allowance ${inspect(error)}`);
        }
      }
      return currentAllowance;
    } catch (error) {
      Logger.error('Check V2 allowance error for', tokenAddress, walletAddress, error);
    }    
  }

  async approveTheMaxAllowanceForRouter(tokenAddress: string, wallet: any) {
    try {
      const tokenContract = new Contract(
        tokenAddress,
        ['function approve(address spender, uint amount) public returns(bool)'],
        wallet
      );
      const maxAllowance = BigInt(MAX_INT_ALLOWANCE);
      const askApprove = await tokenContract.approve(
        this.swapV2ContractAddress,
        maxAllowance,
      );
      const receiptApprove = await askApprove.wait();
      Logger.info(`Approved max allowance for`, tokenAddress, wallet.address, receiptApprove);

      try {
        await this.cacheTokenAllowanceByAddress(wallet.address, tokenAddress, this.swapV2ContractAddress, maxAllowance.toString());
      } catch (error) {
        Logger.error(`approveTheMaxAllowanceForV2Router ${inspect(error)}`);
       }

      return true;
    } catch (error) {
      Logger.error(`Approval error`, error);
      return false;
    }
  }

  async getBestTrade(token1: string, token2: string, amount: number): Promise<BestTrade> {
    const makerContract = new Contract(token1, ERC20_ABI, this.provider);

    const traderJoeQuoterInterface = new utils.Interface(TRADER_JOE_V2_QUOTER_ABI);
    const traderJoeQuoterContract = new Contract(
        this.quoteV2ContractAddress,
        traderJoeQuoterInterface,
        this.provider
    );

    const bnAmount = BigInt(amount * 10 ** (await makerContract.decimals()));

    let route: Array<string>;
    if(token1 === this.usdcAvalanche || token2 === this.usdcAvalanche) {
        route = [token1, token2];
    } else {
        route = [token1, this.usdcAvalanche, token2];
    }

    return await traderJoeQuoterContract.findBestPathFromAmountIn(route, bnAmount.toString());
  }

  async getEstimation(token1: string, token2: string, amount: number, slippage: number) {
    if (!this.provider) { await this.initiateConnection(); }

    // Here we should connect to the quoter contract to get the best path and the price quote.
    const bestTrade = await this.getBestTrade(token1, token2, amount);
    //return bestTrade;
    const token2Contract = new Contract(token2, ERC20_ABI, this.provider);

    const price = parseFloat((Number(bestTrade.amounts[bestTrade.amounts.length - 1]) / (10 ** (await token2Contract.decimals()))).toFixed(6));
    const minimumAmountOut = price * slippage / 100;

    return {
      pair: '',
      from: token1,
      to: token2,
      slippageTorelance: slippage,
      amount,
      price: price,
      priceImpact: 0, //priceImpact,
      minimumReceived: minimumAmountOut, // Number(minAmountOut.toSignificant()),
      fee: (amount * LIQUIDITY_PROVIDER_FEE_PERCENTAGE) / 100
    }
  }

  async getPayloadForSwap(walletAddress: string, token1: string, token2: string, amount: number, slippage: number) {
    try {
        Logger.info('getPayloadForSwapV2-------------------');
        const bestTrade = await this.getBestTrade(token1, token2, amount);
  
        const block = await this.provider.getBlock('latest');
        const deadline = block.timestamp + 300;
        
        const token2Contract = new Contract(token2, ERC20_ABI, this.provider);
        const decimals = await token2Contract.decimals();
        const price = parseFloat(Number((bestTrade.amounts[bestTrade.amounts.length - 1] as any) / (10 ** decimals)).toFixed(6));

        const swapPayload = {
          inputAmount: bestTrade.amounts[0],
          outputAmount: bestTrade.amounts[bestTrade.amounts.length - 1],
          path: bestTrade.route,
          binSteps: bestTrade.binSteps,
          versions: bestTrade.versions,
          to: utils.getAddress(walletAddress),
          deadline
        };

        return {
          swapPayload,
          swapDetails: {
            pair: '',
            from: token1,
            to: token2,
            path: bestTrade.route,
            parsedPath: bestTrade.route,
            amount,
            price: price.toString(),
            fee: (amount * LIQUIDITY_PROVIDER_FEE_PERCENTAGE) / 100
          },
          allowance: await this.checkAllowance(token1, walletAddress)
        };
      } catch (error) {
        Logger.error('swapTargetPayloadForSwapTransactionV2', error);
        return { error: 1, message: `Insufficient liquidity - ${error?.message} !!` };
      }
  }

  async swapOrderMarketPlace(wallet: any, marketPayload: any) {
    const traderJoeInterface: any = new utils.Interface(TRADER_JOE_V2_ABI);
    const traderJoeContract = new Contract(
      this.swapV2ContractAddress,
      traderJoeInterface,
      wallet
    );

    const path = [
      marketPayload.binSteps,
      marketPayload.versions,
      marketPayload.path
    ];

    try {
      const formatInputAmount = BigInt(marketPayload.inputAmount);
      const formatOutputAmount = BigInt(marketPayload.outputAmount) * BigInt(95) / BigInt(100);
      
      let txSwap: any, receiptTxSwap: any;
      
      if(marketPayload.avaxFrom === true) {
        txSwap = await traderJoeContract.swapExactNATIVEForTokensSupportingFeeOnTransferTokens(
          formatOutputAmount,
          path,
          marketPayload.to,
          marketPayload.deadline,
          {value: formatInputAmount}
        );

        receiptTxSwap = await txSwap.wait();
        Logger.info(`V2 NATIVE-to-token transaction success hash: \n${txSwap.hash} \nTransaction was mined in block: ${receiptTxSwap.blockNumber}\n`);  
      } else if (marketPayload.avaxTo === true) {
        txSwap = await traderJoeContract.swapExactTokensForNATIVESupportingFeeOnTransferTokens(
          formatInputAmount,
          formatOutputAmount,
          path,
          marketPayload.to,
          marketPayload.deadline,
        );

        receiptTxSwap = await txSwap.wait();
        Logger.info(`V2 token-to-NATIVE transaction success hash: \n${txSwap.hash} \nTransaction was mined in block: ${receiptTxSwap.blockNumber}\n`);  
      } else {
        txSwap = await traderJoeContract.swapExactTokensForTokensSupportingFeeOnTransferTokens(
          formatInputAmount,
          formatOutputAmount,
          path,
          marketPayload.to,
          marketPayload.deadline,
        );
        
        receiptTxSwap = await txSwap.wait();
        Logger.info(`V2 transaction success hash: \n${txSwap.hash} \nTransaction was mined in block: ${receiptTxSwap.blockNumber}\n`);  
      }
      
      return {
        transactionHash: txSwap.hash,
        transactionBlock: receiptTxSwap.blockNumber
      };
      
    } catch (error) {
      Logger.error('Swap token error using trader-joe-v2', marketPayload, wallet.address, error);
      return { error: 2, message: 'Market Place order on V2 failed! Please try again' };
    }
  }

  async swapOrderMarketPlaceAVAX(wallet: any, marketPayload: any) {

  }
}