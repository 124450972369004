import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DebounceClickDirective } from './click-debounce.directive';
import { DebounceKeyupDirective } from './keyup-debounce.directive';
@NgModule({
  declarations: [DebounceClickDirective, DebounceKeyupDirective],
  imports: [CommonModule, IonicModule, TranslateModule],
  exports: [DebounceClickDirective, DebounceKeyupDirective]
})
export class DebounceDirectiveModule { }
