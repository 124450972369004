import { TokenDefinition } from '../../../global';
import { AGX_GUID, AGX_KEYRING_ID, AUX_GUID, AUX_KEYRING_ID } from '../../constants';
import { contract_abis } from './token-abi';

export const supportedAssetList: { [key: string]: TokenDefinition } = {
  // Key needs to be asset guid of asset
  // Adding the guid of a sys asset will allow the asset page to have a specified image/page title
  // Assets symbol and guid are set in wallet.service.ts... like '{guid}',
  SYS: {
    name: 'Syscoin',
    url: 'http://syscoin.org',
    logo: '../../assets/images/coins/syscoin_utxo.png',
    baseChainSymbol: 'SYS',
    coinInfo: 'coin_info.sys',
    coingeckoId: 'syscoin',
    colorPalette: {
      assetGradientColors: ['#3475BC', '#073B74']
    },
    symbol: 'SYS',
    precision: 8
  },
  SYSNEVM: {
    symbol: 'SYSNEVM',
    name: 'SYS-ROLLUX',
    url: 'https://syscoin.org/',
    logo: '../../assets/images/coins/syscoin.png',
    coinInfo: 'coin_info.sys',
    baseChainSymbol: 'SYSNEVM',
    coingeckoId: 'syscoin',
    colorPalette: {
      assetGradientColors: ['#3475BC', '#073B74']
    },
    precision: 18
  },
  'SYSNEVM-0x39bD164003Ea4F7Dea8744C7cc384dA06C16fef5': {
    chainId: 570,
    assetGuid: '0x39bD164003Ea4F7Dea8744C7cc384dA06C16fef5',
    owner_address: '',
    symbol: 'AUX',
    name: 'AUX Coin',
    url: 'http://agxpay.com',
    logo: '../../assets/images/coins/aux_token.png',
    chainLogo: '../../assets/images/coins/AUX_token_chain.png',
    baseChainSymbol: 'SYSNEVM',
    coinInfo: 'coin_info.aux',
    coingeckoId: 'aux-coin',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#FFBB00', '#F78D2B']
    },
    precision: 18
  },
  'SYSNEVM-0x3a656c0F5c157A4710311694bBC8bb51A468446B': {
    chainId: 570,
    assetGuid: '0x3a656c0F5c157A4710311694bBC8bb51A468446B',
    owner_address: '',
    symbol: 'AGX',
    name: 'AGX Coin',
    url: 'http://agxpay.com',
    logo: '../../assets/images/coins/agx_token.png',
    chainLogo: '../../assets/images/coins/AGX_token_chain.png',
    baseChainSymbol: 'SYSNEVM',
    coinInfo: 'coin_info.agx',
    coingeckoId: 'agx-coin',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#BBC5D1', '#525252']
    },
    precision: 18
  },
  'SYSNEVM-0xC53Fb4f4838D0D341d6F64634aa6C5f45b5d7bC7': {
    chainId: 57000,
    assetGuid: '0xC53Fb4f4838D0D341d6F64634aa6C5f45b5d7bC7',
    owner_address: '',
    symbol: 'AUX',
    name: 'AUX Coin',
    url: 'http://agxpay.com',
    logo: '../../assets/images/coins/aux_token.png',
    chainLogo: '../../assets/images/coins/AUX_token_chain.png',
    baseChainSymbol: 'SYS',
    coinInfo: 'coin_info.aux',
    coingeckoId: 'aux-coin',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#FFBB00', '#F78D2B']
    },
    precision: 18
  },
  'SYSNEVM-0x6786bad0E883E4ca1fBa3e549eb36633146c6C09': {
    chainId: 57000,
    assetGuid: '0x6786bad0E883E4ca1fBa3e549eb36633146c6C09',
    owner_address: '',
    symbol: 'AGX',
    name: 'AGX Coin',
    url: 'http://agxpay.com',
    logo: '../../assets/images/coins/agx_token.png',
    chainLogo: '../../assets/images/coins/AGX_token_chain.png',
    baseChainSymbol: 'SYS',
    coinInfo: 'coin_info.agx',
    coingeckoId: 'agx-coin',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#BBC5D1', '#525252']
    },
    precision: 18
  },
  ETH: {
    symbol: 'ETH',
    name: 'Ethereum',
    url: 'http://ethereum.org',
    logo: '../../assets/images/coins/ethereum.png',
    coinInfo: 'coin_info.eth',
    baseChainSymbol: 'ETH',
    coingeckoId: 'ethereum',
    colorPalette: {
      assetGradientColors: ['#7E8083', '#484B4E']
    },
    precision: 18
  },
  'ETH-0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48': {
    chainId: 1,
    assetGuid: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    asset_publicvalue: 'USDC Coin',
    isOwner: false,
    owner_address: '',
    symbol: 'USDC',
    name: 'USDC Coin',
    url: 'https://www.coinbase.com/usdc',
    logo: '../../assets/images/coins/usdc_token.png',
    chainLogo: '../../assets/images/coins/usdc_token.png',
    baseChainSymbol: 'ETH',
    coinInfo: 'coin_info.usdc',
    coingeckoId: 'usd-coin',
    colorPalette: {
      assetGradientColors: ['#9959C7', '#2566B2']
    },
    precision: 6
  },
  'ETH-0xa6234ed33703244495B2739C2BbBA666456dbc80': {
    chainId: 1,
    assetGuid: '0xa6234ed33703244495B2739C2BbBA666456dbc80',
    owner_address: '',
    symbol: 'AUX',
    name: 'AUX Coin',
    url: 'http://agxpay.com',
    logo: '../../assets/images/coins/aux_token.png',
    chainLogo: '../../assets/images/coins/AUX_token_chain.png',
    baseChainSymbol: 'ETH',
    coinInfo: 'coin_info.aux',
    coingeckoId: 'aux-coin',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#FFBB00', '#F78D2B']
    },
    precision: 18
  },
  'ETH-0x5fE6A182974e158885c112126302b7575Bf0436e': {
    chainId: 1,
    assetGuid: '0x5fE6A182974e158885c112126302b7575Bf0436e',
    owner_address: '',
    symbol: 'AGX',
    name: 'AGX Coin',
    url: 'http://agxpay.com',
    logo: '../../assets/images/coins/agx_token.png',
    chainLogo: '../../assets/images/coins/AGX_token_chain.png',
    baseChainSymbol: 'ETH',
    coinInfo: 'coin_info.agx',
    coingeckoId: 'agx-coin',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#BBC5D1', '#525252']
    },
    precision: 18
  },
  // 'ETH-0x3a656c0F5c157A4710311694bBC8bb51A468446B': {
  //   chainId: 1,
  //   assetGuid: '0x3a656c0F5c157A4710311694bBC8bb51A468446B',
  //   owner_address: '',
  //   symbol: 'LODE',
  //   name: 'LODE Token',
  //   url: 'http://agxpay.com',
  //   logo: '../../assets/images/coins/lode_token.png',
  //   chainLogo: '../../assets/images/coins/LODE_token_chain.png',
  //   baseChainSymbol: 'ETH',
  //   coinInfo: 'coin_info.lode_avax',
  //   coingeckoId: 'lode-token',
  //   disabled: false,
  //   colorPalette: {
  //     assetGradientColors: ['#43CAF1', '#0061C8']
  //   },
  //   precision: 18
  // },
  'ETH-0xdAC17F958D2ee523a2206206994597C13D831ec7': {
    chainId: 1,
    assetGuid: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    owner_address: '',
    symbol: 'USDT',
    name: 'USDT Coin',
    url: 'https://tether.to/',
    logo: '../../assets/images/coins/usdt_token.png',
    chainLogo: '../../assets/images/coins/usdt_token.png',
    baseChainSymbol: 'ETH',
    coinInfo: 'coin_info.usdt',
    coingeckoId: 'tether',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#FFBB00', '#F78D2B']
    },
    precision: 6
  },
  'ETH-0x2cA886799aa5B15343849370D99187Ae729dF0B3': {
    chainId: 11155111,
    assetGuid: '0x2cA886799aa5B15343849370D99187Ae729dF0B3',
    owner_address: '',
    symbol: 'AUX',
    name: 'AUX Coin',
    url: 'http://agxpay.com',
    logo: '../../assets/images/coins/aux_token.png',
    chainLogo: '../../assets/images/coins/AUX_token_chain.png',
    baseChainSymbol: 'ETH',
    coinInfo: 'coin_info.aux',
    coingeckoId: 'aux-coin',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#FFBB00', '#F78D2B']
    },
    precision: 18
  },
  'ETH-0x32e1b69C961DBA85e6AdFa933b5C52C743A8f390': {
    chainId: 11155111,
    assetGuid: '0x32e1b69C961DBA85e6AdFa933b5C52C743A8f390',
    owner_address: '',
    symbol: 'AGX',
    name: 'AGX Coin',
    url: 'http://agxpay.com',
    logo: '../../assets/images/coins/agx_token.png',
    chainLogo: '../../assets/images/coins/AGX_token_chain.png',
    baseChainSymbol: 'ETH',
    coinInfo: 'coin_info.agx',
    coingeckoId: 'agx-coin',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#BBC5D1', '#525252']
    },
    precision: 18
  },
  // 'ETH-0x2F120935177552E63Fa0d1Ed46549aa6F1353E57': {
  //   chainId: 11155111,
  //   assetGuid: '0x2F120935177552E63Fa0d1Ed46549aa6F1353E57',
  //   owner_address: '',
  //   symbol: 'LODE',
  //   name: 'LODE Token',
  //   url: 'http://agxpay.com',
  //   logo: '../../assets/images/coins/lode_token.png',
  //   chainLogo: '../../assets/images/coins/LODE_token_chain.png',
  //   baseChainSymbol: 'ETH',
  //   coinInfo: 'coin_info.lode_avax',
  //   coingeckoId: 'lode-token',
  //   disabled: false,
  //   colorPalette: {
  //     assetGradientColors: ['#43CAF1', '#0061C8']
  //   },
  //   precision: 18
  // },
  'ETH-0x07865c6E87B9F70255377e024ace6630C1Eaa37F': {
    chainId: 5,
    assetGuid: '0x07865c6E87B9F70255377e024ace6630C1Eaa37F',
    asset_publicvalue: 'USDC Coin',
    isOwner: false,
    owner_address: '',
    symbol: 'USDC',
    name: 'USDC Coin',
    url: 'https://www.coinbase.com/usdc',
    logo: '../../assets/images/coins/usdc_token.png',
    chainLogo: '../../assets/images/coins/usdc_token.png',
    baseChainSymbol: 'ETH',
    coinInfo: 'coin_info.usdc',
    coingeckoId: 'usd-coin',
    colorPalette: {
      assetGradientColors: ['#9959C7', '#2566B2']
    },
    precision: 6
  },
  AVAX: {
    symbol: 'AVAX',
    assetGuid: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    wrapped: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    name: 'Avalanche',
    url: 'https://www.avax.network',
    logo: '../../assets/images/coins/avax_token.png',
    coinInfo: 'coin_info.avax',
    baseChainSymbol: 'AVAX',
    coingeckoId: 'avalanche-2',
    colorPalette: {
      assetGradientColors: ['#E44140', '#C22D37']
    },
    precision: 18
  },
  // Main-net tokens
  'AVAX-0x68327a91E79f87F501bC8522fc333FB7A72393cb': {
    chainId: 43114,
    assetGuid: '0x68327a91E79f87F501bC8522fc333FB7A72393cb',
    owner_address: '',
    symbol: 'AUX',
    name: 'AUX Coin',
    url: 'http://agxpay.com',
    logo: '../../assets/images/coins/aux_token.png',
    chainLogo: '../../assets/images/coins/AUX_token_chain.png',
    baseChainSymbol: 'AVAX',
    coinInfo: 'coin_info.aux',
    coingeckoId: 'aux-coin',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#FFBB00', '#F78D2B']
    },
    precision: 18
  },
  'AVAX-0x13E7bceFddE72492E656f3fa58baE6029708e673': {
    chainId: 43114,
    assetGuid: '0x13E7bceFddE72492E656f3fa58baE6029708e673',
    owner_address: '',
    symbol: 'AGX',
    name: 'AGX Coin',
    url: 'http://agxpay.com',
    logo: '../../assets/images/coins/agx_token.png',
    chainLogo: '../../assets/images/coins/AGX_token_chain.png',
    baseChainSymbol: 'AVAX',
    coinInfo: 'coin_info.agx',
    coingeckoId: 'agx-coin',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#BBC5D1', '#525252']
    },
    precision: 18
  },
  'AVAX-0xbBAAA0420D474B34Be197f95A323C2fF3829E811': {
    chainId: 43114,
    assetGuid: '0xbBAAA0420D474B34Be197f95A323C2fF3829E811',
    owner_address: '',
    symbol: 'LODE',
    name: 'LOD3 Token',
    url: 'http://agxpay.com',
    logo: '../../assets/images/coins/lode_token.png',
    chainLogo: '../../assets/images/coins/LODE_token_chain.png',
    baseChainSymbol: 'AVAX',
    coinInfo: 'coin_info.lode_avax',
    coingeckoId: 'lode-token',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#43CAF1', '#0061C8']
    },
    precision: 17
  },
  'AVAX-0x46856274bc823D9B2aaA47D1ecDD35d0D29b485e': {
    chainId: 43114,
    assetGuid: '0x46856274bc823D9B2aaA47D1ecDD35d0D29b485e',
    owner_address: '',
    symbol: 'LTC',
    name: 'LOD3 Token Classic',
    url: 'http://agxpay.com',
    logo: '../../assets/images/coins//LTC_token_chain.png',
    chainLogo: '../../assets/images/coins/LTC_token_chain.png',
    baseChainSymbol: 'AVAX',
    coinInfo: 'coin_info.lode_avax',
    coingeckoId: 'lode-token',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#43CAF1', '#0061C8']
    },
    precision: 17
  },
  'AVAX-0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E': {
    chainId: 43114,
    assetGuid: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
    owner_address: '',
    symbol: 'USDC',
    name: 'USDC Coin',
    baseChainSymbol: 'AVAX',
    url: 'https://www.coinbase.com/usdc',
    logo: '../../assets/images/coins/usdc_token.png',
    chainLogo: '../../assets/images/coins/usdc_token.png',
    coinInfo: 'coin_info.usdc',
    coingeckoId: 'usd-coin',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#9959C7', '#2566B2']
    },
    precision: 6
  },
  'AVAX-0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664': {
    chainId: 43114,
    assetGuid: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
    owner_address: '',
    symbol: 'USDC.e',
    name: 'USDC.e Coin',
    baseChainSymbol: 'AVAX',
    url: 'https://www.coinbase.com/usdc',
    logo: '../../assets/images/coins/usdc_token.png',
    chainLogo: '../../assets/images/coins/usdc_token.png',
    coinInfo: 'coin_info.usdc',
    coingeckoId: 'usd-coin-avalanche-bridged-usdc-e',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#9959C7', '#2566B2']
    },
    precision: 6
  },
  'AVAX-0xd6711933736d739813Fd248C0683aDa46691bFD7': {
    chainId: 43114,
    assetGuid: '0xd6711933736d739813Fd248C0683aDa46691bFD7',
    owner_address: '',
    symbol: 'GSD',
    name: 'Gold Standard DAO Token',
    url: 'http://agxpay.com',
    logo: '../../assets/images/coins/gsd_token.png',
    baseChainSymbol: 'AVAX',
    coinInfo: 'coin_info.gsd',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#FFBB00', '#F78D2B']
    },
    precision: 18
  },
  'AVAX-0xEA198f1b44A96a5e36e1E32E18d031936DCe9b2b': {
    chainId: 43114,
    assetGuid: '0xEA198f1b44A96a5e36e1E32E18d031936DCe9b2b',
    owner_address: '',
    symbol: 'SST',
    name: 'Silver Stream Token',
    baseChainSymbol: 'AVAX',
    url: '',
    logo: '../../assets/images/coins/sst.png',
    coinInfo: '',
    coingeckoId: '',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#9959C7', '#2566B2']
    },
    precision: 18
  },
  'AVAX-0x52D6cb3e0DbFc7cA0dccbCda71011B07093FDd31': {
    chainId: 43114,
    assetGuid: '0x52D6cb3e0DbFc7cA0dccbCda71011B07093FDd31',
    owner_address: '',
    symbol: 'VOT3',
    name: 'VOT3 Token',
    baseChainSymbol: 'AVAX',
    url: '',
    logo: '../../assets/images/coins/VOT3_token.png',
    coinInfo: '',
    coingeckoId: '',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#9959C7', '#2566B2']
    },
    precision: 17
  },
  'AVAX-0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7': {
    chainId: 43114,
    assetGuid: '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
    owner_address: '',
    symbol: 'USDT',
    name: 'USDt Coin',
    url: 'https://tether.to/',
    logo: '../../assets/images/coins/usdt_token.png',
    chainLogo: '../../assets/images/coins/usdt_token.png',
    baseChainSymbol: 'AVAX',
    coinInfo: 'coin_info.usdt',
    coingeckoId: 'tether',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#FFBB00', '#F78D2B']
    },
    precision: 6
  },
  // Test-net tokens
  'AVAX-0x287a2f1772C64a245ab3fa3Ee1227a25e3961F45': {
    chainId: 43113,
    assetGuid: '0x287a2f1772C64a245ab3fa3Ee1227a25e3961F45',
    owner_address: '',
    symbol: 'AUX',
    name: 'AUX Coin',
    url: 'http://agxpay.com',
    logo: '../../assets/images/coins/aux_token.png',
    chainLogo: '../../assets/images/coins/AUX_token_chain.png',
    baseChainSymbol: 'AVAX',
    coinInfo: 'coin_info.aux',
    coingeckoId: 'aux-coin',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#FFBB00', '#F78D2B']
    },
    precision: 18
  },
  'AVAX-0x905F3f24404dF6042E1dF6A52CC6bB41EE6BE432': {
    chainId: 43113,
    assetGuid: '0x905F3f24404dF6042E1dF6A52CC6bB41EE6BE432',
    owner_address: '',
    symbol: 'AGX',
    name: 'AGX Coin',
    url: 'http://agxpay.com',
    logo: '../../assets/images/coins/agx_token.png',
    chainLogo: '../../assets/images/coins/AGX_token_chain.png',
    baseChainSymbol: 'AVAX',
    coinInfo: 'coin_info.agx',
    coingeckoId: 'agx-coin',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#BBC5D1', '#525252']
    },
    precision: 18
  },
  'AVAX-0x9792407864A79A11A651842bBe483228519fA9B8': {
    chainId: 43113,
    assetGuid: '0x9792407864A79A11A651842bBe483228519fA9B8',
    owner_address: '',
    symbol: 'LODE',
    name: 'LOD3 Token',
    url: 'http://agxpay.com',
    logo: '../../assets/images/coins/lode_token.png',
    chainLogo: '../../assets/images/coins/LODE_token_chain.png',
    baseChainSymbol: 'AVAX',
    coinInfo: 'coin_info.lode_avax',
    coingeckoId: 'lode-token',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#43CAF1', '#0061C8']
    },
    precision: 17
  },
  'AVAX-0xca838e08E26AE5B34f4572B8B51174C338a619e7': {
    chainId: 43113,
    assetGuid: '0xca838e08E26AE5B34f4572B8B51174C338a619e7',
    owner_address: '',
    symbol: 'LTC',
    name: 'LOD3 Token Classic',
    url: 'http://agxpay.com',
    logo: '../../assets/images/coins/lode_token.png',
    chainLogo: '../../assets/images/coins/LTC_token_chain.png',
    baseChainSymbol: 'AVAX',
    coinInfo: 'coin_info.lode_avax',
    coingeckoId: 'lode-token',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#43CAF1', '#0061C8']
    },
    precision: 17
  },
  'AVAX-0xB6076C93701D6a07266c31066B298AeC6dd65c2d': {
    chainId: 43113,
    assetGuid: '0xB6076C93701D6a07266c31066B298AeC6dd65c2d',
    owner_address: '',
    symbol: 'USDC',
    name: 'USDC Coin',
    baseChainSymbol: 'AVAX',
    url: 'https://www.coinbase.com/usdc',
    logo: '../../assets/images/coins/usdc_token.png',
    chainLogo: '../../assets/images/coins/usdc_token.png',
    coinInfo: 'coin_info.usdc',
    coingeckoId: 'usd-coin',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#9959C7', '#2566B2']
    },
    precision: 6
  },
  
  'AVAX-0x4B03642Cc03bA526cF5421284660F9dc31Ef8dD5': {
    chainId: 43113,
    assetGuid: '0x4B03642Cc03bA526cF5421284660F9dc31Ef8dD5',
    owner_address: '',
    symbol: 'USDC.e',
    name: 'USDC.e Coin',
    baseChainSymbol: 'AVAX',
    url: 'https://www.coinbase.com/usdc',
    logo: '../../assets/images/coins/usdc_token.png',
    chainLogo: '../../assets/images/coins/usdc_token.png',
    coinInfo: 'coin_info.usdc',
    coingeckoId: 'usd-coin-avalanche-bridged-usdc-e',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#9959C7', '#2566B2']
    },
    precision: 6
  },
  'AVAX-0x078A20183B06E3DDa64B96bB0a2c78231cdF3e41': {
    chainId: 43113,
    assetGuid: '0x078A20183B06E3DDa64B96bB0a2c78231cdF3e41',
    owner_address: '',
    symbol: 'GSD',
    name: 'Gold Standard DAO Token',
    url: 'http://agxpay.com',
    logo: '../../assets/images/coins/gsd_token.png',
    baseChainSymbol: 'AVAX',
    coinInfo: 'coin_info.lode_avax',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#FFBB00', '#F78D2B']
    },
    precision: 18
  },
  'AVAX-0x63d5f0c8AA38a2bF8a72Ec47F20E495FfCbfe12F': {
    chainId: 43113,
    assetGuid: '0x63d5f0c8AA38a2bF8a72Ec47F20E495FfCbfe12F',
    owner_address: '',
    symbol: 'SST',
    name: 'Silver Stream Token',
    baseChainSymbol: 'AVAX',
    url: '',
    logo: '../../assets/images/coins/sst.png',
    coinInfo: '',
    coingeckoId: '',
    disabled: false,
    colorPalette: {
      assetGradientColors: ['#9959C7', '#2566B2']
    },
    precision: 18
  },

  BTC: {
    symbol: 'BTC',
    name: 'Bitcoin',
    url: 'http://ethereum.org',
    logo: '../../assets/images/coins/btc_token.png',
    coinInfo: 'coin_info.btc',
    baseChainSymbol: 'BTC',
    coingeckoId: 'bitcoin',
    colorPalette: {
      assetGradientColors: ['#7E8083', '#484B4E']
    },
    precision: 8
  },
};


export function findAbiByAddress(contractAddress: string): any {
 return contract_abis.find(abi => abi.contract == contractAddress)?.abi;
}
